<template>
<!-- 提醒设置 -->
    <div class="content">
        <div v-for="item in attentionlistdata" :key="item.name" class="attentionlist">
            <div class="leftAttention">
                    <div>{{item.text}}</div>
            </div>
            <div class="rightAttention">
                <el-switch
                    v-model="item.value"
                    active-color="#3D7EFF"
                    inactive-color="#E9E9EA">
                    </el-switch>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'personalCenter-myattention',
    data() {
        return{
            value: false,
            attentionlistdata:[
                {
                    text: '开启提醒设置',
                    value: true
                },
                {
                    text: '开启周清提醒',
                    value: true
                },
                {
                    text: '开启月清提醒',
                    value: false
                }
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
    .content{
        width: calc(100% - 60px);
        background: white;
        display: inline-block;
        margin-left: 30px;
        margin-top: 20px;
        overflow: auto;
        .attentionlist{
            height: 56px;
            box-shadow: 0px 1px 0px 0px #EEEEEE;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .rightAttention{
                width: 64px;
                height: 25px;
                margin-right: 15px;
            }
            .leftAttention{
                display: flex;
                align-items: center;
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                padding-left: 15px;
            }
        }
    }
</style>